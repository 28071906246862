import * as Support from './Utils/support';
import * as Messaging from './Utils/messaging';
import * as Referral from './Utils/referral';

const merged = {
  ...Support,
  ...Messaging,
  ...Referral,

}

export default merged;
