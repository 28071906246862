import logo from './logo.svg';
import './Sass/App.scss';
import React, {useState, useContext, lazy, Suspense} from 'react'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect
} from "react-router-dom";

import About from './Pages/Landing/About'
import Contact from './Pages/Landing/Contact'
import Hermes from './Pages/Landing/Hermes'
import HermesLatest from './Pages/Landing/HermesLatest'
import HermesOnboarding from './Pages/Landing/HermesOnboarding'
import Home from './Pages/Landing/Home'
import Privacy from './Pages/Landing/Privacy'
import TermsOfService from './Pages/Landing/TermsOfService'
import SettingsManager from './Pages/Landing/SettingsManager'

function App() {
  return (
    <Router>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/contact" element={<Contact/>}/>

          <Route exact path="/hermes" element={<Hermes/>}/>
          <Route exact path="/hermes/latest" element={<HermesLatest/>}/>
          <Route exact path="/hermes/onboarding" element={<HermesOnboarding/>}/>
          <Route exact path="/hermes/privacy" element={<Privacy/>}/>
          <Route exact path="/hermes/tos" element={<TermsOfService/>}/>

          <Route exact path="/admin/settings" element={<SettingsManager/>}/>
        </Routes>
    </Router>
  );
}

export default App;
