import React, { useState, useEffect } from 'react';
import { NavBar } from '../../Components/Nav/NavBar';
import Button from 'react-bootstrap/Button';
import { TextInput } from '../../Components/Input/Input';
import Modal from 'react-bootstrap/Modal';
import Footer from './Footer';
import Form from 'react-bootstrap/Form'
import { validateEmail, push } from '../../Utils/validation';
import { Root } from '../../Api/Root';
import s from './styles/Contact.module.scss';
import {Tile} from '../../Components/Tile/Tile'
import { Helmet } from 'react-helmet';

const SettingsManager = () => {

    const [request, setRequest] = useState({})
    const [settings, setSettings] = useState(null)
    const [formErrors, setFormErrors] = useState({})
    const [formResponse, setFormResponse] = useState(null)
    const [formResponseError, setFormResponseError] = useState(null)

    const [restartFormErrors, setRestartFormErrors] = useState({})
    const [restartFormResponse, setRestartFormResponse] = useState(null)
    const [restartFormResponseError, setRestartFormResponseError] = useState(null)

    const [recentLogs, setRecentLogs] = useState("Fetching logs...")
    const [logModalVisible, setLogModalVisible] = useState(false)

    useEffect(() => {
      Root.settingsApi.getAllSettings()
      .then(res => {
        setSettings(res.data)
      })
      .catch(error => {})
    }, []);

    function deleteSetting(name) {
      Root.settingsApi.removeSetting(name).then(() => {
        Root.settingsApi.refreshActuator().then(() => {
          Root.settingsApi.getAllSettings()
          .then(res => {
            setSettings(res.data)
          })
          .catch(error => {})
        });
      }).catch(() =>
        setFormResponseError("Something went wrong, please try again later")
      )
    }

    function handleChange (event) {
      const { name, value } = event.target
        setRequest(prevState => ({ ...prevState, [name]: value }))
    }

      function validate (request) {
        var errors = {}
        if(!request.name) {
          push(errors, 'name', 'A name is required ')
        }
        if (!request.value) {
          push(errors, 'value', 'A value is required')
        }
        return errors
      }

      function validateRestart (request) {
        var errors = {}
        if(!request.user) {
          push(errors, 'user', 'A user is required ')
        }
        if (!request.password) {
          push(errors, 'password', 'A password is required')
        }
        return errors
      }

      function handleSubmit () {
        setFormErrors({});
        setFormResponse(null);
        setFormResponseError(null);
        const errors = validate(request)
            if (Object.keys(errors).length > 0) {
            setFormErrors(errors)
            return
        }
          if(Object.keys(request).length > 0){
            Root.settingsApi.setSettingValue(request.name, request.value).then(() => {
                setFormResponse("Setting created");
                setRequest({});
                setFormErrors({});
                Root.settingsApi.refreshActuator().then(() => {
                  Root.settingsApi.getAllSettings()
                  .then(res => {
                    setSettings(res.data)
                  })
                  .catch(error => {})
                });
            }).catch(() =>
            setFormResponseError("Something went wrong, please try again later")
          )
        }
      }

      function restartServer () {
        setRestartFormErrors({});
        setRestartFormResponse(null);
        setRestartFormResponseError(null);
        const errors = validateRestart(request)
            if (Object.keys(errors).length > 0) {
            setRestartFormErrors(errors)
            return
        }
        if (Object.keys(request).length > 0) {
          Root.settingsApi.restartServer(request.user, request.password).then((response) => {
              if (response.data) {
                setRestartFormResponse(response.data);
              } else {
                setRestartFormResponse("There was an issue with your request");
              }

              setRequest({});
              setRestartFormErrors({});
          }).catch(() =>
            setRestartFormResponseError("Something went wrong, please try again later")
          )
        }
      }

      function viewLogs() {
        setRecentLogs("Fetching logs...");
        setLogModalVisible(true);
        Root.settingsApi.fetchLogs().then((response) => {
            if (response.data) {
              setRecentLogs(response.data);
            } else {
              setRecentLogs("There was an issue fetching the logs");
            }
        }).catch(() =>
          setRecentLogs("Something went wrong, please try again later")
        )
      }

      function handleClose() {
        setLogModalVisible(false);
      }
  return (
    <>
    <Helmet>
        <title>Admin - Cassy</title>
      </Helmet>
       <NavBar style={{ backgroundColor:'#FFFFFF'}}/>
      <div className={s.login}>
        <div className={s.form}>
        <h1 className='fs-1'>Server Manager</h1>
        <p className='text-muted mb-4'>Please provide a user and password to authenticate a server restart.</p>
        <Form style={{paddingBottom: "25px"}}>
            <TextInput containerClassName='mb-3' errors={restartFormErrors} name={'user'} value={request.user || ''} onChange={handleChange} type={'text'} placeholder={"user"}/>
            <TextInput containerClassName='mb-3' errors={restartFormErrors} name={'password'} value={request.password || ''} onChange={handleChange} type={'password'} placeholder={"Password"}/>
            <Button className={`btnFill wide mt-3 ${s.submitButton}`} onClick={restartServer}>Restart server</Button>
        </Form>
        <Button className={`btnFill wide mt-1 ${s.submitButton}`} onClick={viewLogs}>View logs</Button>
        <p className='form-error text-center mt-2'>{restartFormResponseError}</p>
        {restartFormResponse && <div style={{textAlign:"center", marginTop:"1em"}}><Tile color='purple'>{restartFormResponse}</Tile></div>}
          <h1 className='fs-1'>Config Manager</h1>
          <p className='text-muted mb-4'>Create an manage configuration flags to dynamically control the functionality of your features.</p>
          <h2 className='fs-4'>Create a new flag</h2>
          <Form style={{paddingBottom: "25px"}}>
              <TextInput containerClassName='mb-3' errors={formErrors} name={'name'} value={request.name || ''} onChange={handleChange} type={'text'} placeholder={"Flag name"}/>
              <TextInput containerClassName='mb-3' errors={formErrors} name={'value'} value={request.value || ''} onChange={handleChange} type={'text'} placeholder={"Value"}/>
              <Button className={`btnFill wide mt-3 ${s.submitButton}`} onClick={handleSubmit}>Create</Button>
          </Form>
          <p className='form-error text-center mt-2'>{formResponseError}</p>
          {formResponse && <div style={{textAlign:"center", marginTop:"1em"}}><Tile color='purple'>{formResponse}</Tile></div>}
          <h2 className='fs-4'>Manage existing flags</h2>
          {settings && settings.map((exp, idx) =>
            <div key={idx} className='inline-block' style={{paddingBottom: "25px"}}>
              <p className='text-muted fs-13 me-4 p-0 trunc'><strong>Name:</strong> {exp.name}</p>
              <p className='text-muted fs-13 me-4 p-0 trunc'><strong>Value:</strong> {exp.value}</p>
              <div className={"d-flex justify-content-between mt-3"}>
                <Button onClick={() => {deleteSetting(exp.name)}} className={`btnFill md me-2 ${s.deleteSettingButton}`}>Delete</Button>
              </div>
            </div>
          )}
          <Modal show={logModalVisible} onHide={handleClose} animation={false} className='modal-xl'>
            <Modal.Header closeButton>
              <Modal.Title>Recent logs</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {recentLogs}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default (SettingsManager);
