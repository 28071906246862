
import axios from 'axios';
import * as vars from './vars';

export function getAllSettings () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/settings',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function setSettingValue (name, value) {
  const data = {
    name,
    value,
  };

  return axios({
      method: 'PUT',
      url: vars.baseURL + '/api/v1/settings',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: data
  })
}

export function getSetting (name) {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/settings/' + name,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function removeSetting (name) {
    return axios({
        method: 'DELETE',
        url: vars.baseURL + '/api/v1/settings/' + name,
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}

export function refreshActuator () {
  return axios({
      method: 'POST',
      url: vars.baseURL + '/actuator/refresh',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: {}
  })
}

export function restartServer (user, password) {
  const data = {
    user,
    password,
  };

  return axios({
      method: 'POST',
      url: vars.baseURL + '/api/v1/restart',
      headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken},
      data: data
  })
}

export function fetchLogs () {
    return axios({
        method: 'GET',
        url: vars.baseURL + '/api/v1/logs',
        headers: { api_key: vars.apiKey, 'X-XSRF-TOKEN': vars.csrfToken}
    })
}
